<template>
    <div>
        <div class="flex align-items-center justify-content-center">
            <div class="flex flex-column">

                <div class="spacer" v-if="[1,3,5].includes(reservation.pRow)"></div>

                <Card 
                    class="shadow-3 m-2 item-card text-center"
                    :class="getClass()"
                    @click="loadDetail(reservation)">
                    <template #header>
                        <div class="pt-0">F-{{ reservation.pRow }}</div>
                    </template>
                    <template #title>
                        <div class="mt-2">{{ reservation.pStyle === 'circle' ? 'T' : 'M' }} - {{ reservation.pCol }}</div>
                    </template>
                </Card>
            </div>

            <div class="item-card hall text-center" v-if="reservation.pCol == 7 && reservation.pRow != 1">
                <i class="fas fa-chevron-up text-2xl my-3 text-center" v-if="reservation.pCol == 7 && [2,3].includes(reservation.pRow)"></i>
                <br>
                <i class="fas fa-chevron-up text-2xl text-center" v-if="reservation.pCol == 7 && [2,3].includes(reservation.pRow)"></i>
                <p class="text-lg font-bold"  v-if="reservation.pCol == 7 && reservation.pRow >= 4">ACCESO</p>
            </div>
        </div>

        <Detail 
            v-if="showDialogDetail" 
            :show="showDialogDetail" 
            :data="dialogData" 
            @handleClose="handleClose"
        />
    </div>
</template>

<script>
    import Detail from './Dialogs/Detail'

    export default {
        name: 'Place',
        components: {
            Detail,
        },
        props:{
            col: {
                type: Object,
                default: null,
            },
        },
        data() {
            return {
                reservation: null,
                showDialogDetail: false,
                dialogData: [],
                multiple:{
                    status: false,
                    place: [],
                    night: null,
                }
            }
        },
        created() {
            this.reservation = this.col
        },
        methods: {
            loadDetail(reservation){

                this.multiple = JSON.parse(localStorage.getItem('multiReservation'));
                
                // Si multiple está inactivo, simplemente cargo el detalle. 
                if(!this.multiple || !this.multiple.status){
                    this.dialogData = reservation
                    this.showDialogDetail = true
                }

                // Si multiple está activo lo trato como una reserva multiple. 
                if(this.multiple && this.multiple.status){
                    if(reservation.rId){
                        this.FormRequestFail('La mesa ya está reservada por favor, seleccione otra.', true)
                    }else{
                        if(this.multiple.place.includes(reservation.pId)){
                            const index = this.multiple.place.indexOf(reservation.pId);
                            this.multiple.place.splice(index, 1);
                        }else{
                            this.multiple.place.push(reservation.pId);
                        }
                    }

                    localStorage.setItem('multiReservation', JSON.stringify(this.multiple))
                }

            },
            handleClose(result){
                this.reservation = result
                this.dialogData = null
                this.showDialogDetail = false
                this.emitter.emit('reload-dashboard')
            },
            getClass(){
                return { 
                    'bg-orange-300 text-600': this.reservation.reserved, 
                    'border-circle': this.reservation.pStyle == 'circle', 
                    'border-1 text-400': this.reservation.uAssign, 
                    'border-1 border-pink-800 text-400': this.multiple && this.multiple.place && this.multiple.place.includes(this.reservation.pId) 
                }
            },
        }
    }
</script>

<style>
    .spacer {
        background-color: #7897a5;
        width: 84px;
        height: 3px;
        margin-top: 3px;
        margin-bottom: 3px;
    }


    .item-card{
        width:72px; /* 48 */ 
        height:72px; /* 48 */
    }
    .p-card-body{
        padding: 0 !important;
    }

    .hall{
        width:84px;
        height: 75px;
        border-left: solid 3px #7897a5;
        border-right: solid 3px #7897a5;
    }
</style>
