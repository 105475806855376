<template>
    <div>
      <Dialog 
        :closeOnEscape="false"
        :closable="false"
        v-model:visible="showDialogPrint" 
        :style="{width: '90vw'}" 
        class="p-dialog-maximized"
        :maximizable="true">
        <div id="printArea">
            <div v-for="res in data" :key="res.group.id" style="page-break-after: always;">
                <div style="width: 100%; text-align: right;">
                    <h3>{{ `${res.night} - ${res.group.description} - (${res.group.name})` }}</h3>
                </div>

                <div style="margin: 0 auto; text-align: center;">|
                    <div v-for="(place) in res.places" :key="place.id" :style="{ width: `calc(90% / ${place.count})` }" style= "height: 140px; border: solid 1px black; display: inline-block; text-align: center; margin:2px">
                        <table style="width: 100%; text-align: center;" cellpadding="0" cellspacing="0">
                            <tr><td style="height:20px !important; font-weight: bold; font-size: 12px; border-bottom: solid 1px;">{{`F${ place.row }-${ place.style == 'circle' ? 'T' : 'M'}${place.col}`}}</td></tr>
                            <tr><td style="height:30px !important; font-weight: bold; font-size: 9px; border-bottom: solid 1px;">{{ place.name }}</td></tr>
                            <tr><td style="height:20px !important; font-weight: normal; font-size: 11px; border-bottom: solid 1px;">{{ place.extra ? 'ADICIONALES (' + place.extra + ')' : '' }}</td></tr>
                            <tr><td style="height:50px !important; font-weight: normal; font-size: 11px; border-bottom: solid 1px;"><p style="overflow: hidden; text-overflow: ellipsis;">{{ place.observation }}</p></td></tr>
                            <tr><td style="height:20px !important; font-weight: normal; font-size: 10px;">{{ place.user }}</td></tr>
                        </table>
                        <div class="html2pdf__page-break" v-if="[48].includes(index)" style="page-break-after: always;"></div> <!--  -->
                    </div> 
                </div>
            </div>
        </div>
    
        <template #footer>
            <div class="grid">
                <div class="col-3 sm:col-3 text-left">
                    <Button @click="closeDialog()" label="CERRAR" icon="fas fa-times" class="p-button-outlined p-button-sm p-button-danger" />
                </div>
                <div class="col-9 sm:col-9 text-right">
                    <Button @click="exportToPDF()" icon="fas fa-file-pdf" class="p-button-outlined p-button-sm p-button-primary" autofocus label="PDF"/>
                    <Button @click="print()" icon="fas fa-print" class="p-button-outlined p-button-sm p-button-primary" autofocus label="IMPRIMIR"/>
                </div>
            </div>
        </template>
      </Dialog>
    </div>
</template>

    <style>
        @media print {
            @page {
                size: 210mm 297mm;
                /* Chrome sets own margins, we change these printer settings */
                margin: 10mm;
            }
        }
    </style>
    
    <script>
        import html2pdf from 'html2pdf.js'
    
        export default {
            props:{
                data: {
                    type: Object,
                    default: null,
                },
                load: {
                    type: Boolean,
                    default: false,
                },
                filename: {
                    type: String,
                    default: '',
                }
            },
            data(){
                return {
                    isLoading: false,
                    showDialogPrint: false,
                    ticket: [],
                }
            },
            mounted(){
                this.showDialogPrint = this.load
            },
            methods: {
                async print () {
                    this.$htmlToPaper('printArea');
                },
                closeDialog(){
                    this.$emit('handleClosePrint', '');
                    this.showDialogPrint = false;
                },
                exportToPDF () {
                    let element = document.getElementById('printArea');
                    html2pdf(element, {
                        margin: 0,
                        filename: `${this.filename}.pdf`,
                        image: { type: 'jpeg', quality: 0.98 },
                        html2canvas: { dpi: 300, letterRendering: true },
                        jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
                    })
                }
            }
        }
    </script>